import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot }  from '@angular/router';
import {Constant} from "../constant";

export interface CanComponentDeactivate {
  confirmChangeRouter: (url?) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  previousState: any;
  exceptList: any = []
  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (nextState.url !== Constant.url_login) {
      let temp = undefined;
      if (!temp) {
        let exist = false;
        this.exceptList.forEach((item) => {
          if ('/'+nextState.url.substring(1, nextState.url.length-1) === item) {
            exist = true;
          }
        })
        !exist && ( temp = nextState.url)
      }
      // this.previousState = nextState.url;
      if (temp && component.confirmChangeRouter(nextState.url)) {
        return true;
      } else {
        // this.previousState = temp;
        return false;
      }
    } else {
      return true;
    }
  }
}
