<div>
  <div class="navbar__group">
    <div class="container">
      <nav class="navbar navbar-toggleable-md navbar-inverse">
        <button
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span> </span>
          <span> </span>
          <span> </span>
        </button>
        <a class="navbar-brand">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="134"
            height="36"
            viewBox="0 0 102 27"
            fill="none"
          >
            <path
              d="M23.3499 12.8886H31.7588V16.142H23.3499V12.8886ZM23.6502 22.5238H19.5959V5.00525H32.8349V8.25869H23.6502V22.5238Z"
              fill="#202D41"
            />
            <path
              d="M95.6041 8.48035C97.2725 8.48035 98.6156 8.98088 99.6333 9.98193C100.668 10.983 101.185 12.4679 101.185 14.4366V22.1448H97.2808V15.0373C97.2808 13.9695 97.0473 13.177 96.5801 12.6598C96.1129 12.1259 95.4372 11.8589 94.553 11.8589C93.5686 11.8589 92.7844 12.1676 92.2005 12.7849C91.6165 13.3855 91.3245 14.2865 91.3245 15.4878V22.1448H87.4204V8.68056H91.1494V10.2572C91.6666 9.68996 92.3089 9.25617 93.0764 8.95585C93.8439 8.63885 94.6864 8.48035 95.6041 8.48035Z"
              fill="#202D41"
            />
            <path
              d="M81.6609 8.68056V22.1448H77.9319V20.5932C76.9642 21.7611 75.5628 22.345 73.7275 22.345C72.4595 22.345 71.3083 22.0614 70.2738 21.4941C69.2561 20.9268 68.4552 20.1177 67.8713 19.0665C67.2873 18.0154 66.9954 16.7975 66.9954 15.4127C66.9954 14.0279 67.2873 12.8099 67.8713 11.7588C68.4552 10.7077 69.2561 9.89851 70.2738 9.33125C71.3083 8.76398 72.4595 8.48035 73.7275 8.48035C75.446 8.48035 76.7891 9.02259 77.7567 10.1071V8.68056H81.6609ZM74.4032 19.1416C75.3876 19.1416 76.2051 18.8079 76.8558 18.1406C77.5065 17.4565 77.8318 16.5472 77.8318 15.4127C77.8318 14.2781 77.5065 13.3772 76.8558 12.7098C76.2051 12.0258 75.3876 11.6837 74.4032 11.6837C73.4021 11.6837 72.5763 12.0258 71.9256 12.7098C71.2749 13.3772 70.9495 14.2781 70.9495 15.4127C70.9495 16.5472 71.2749 17.4565 71.9256 18.1406C72.5763 18.8079 73.4021 19.1416 74.4032 19.1416Z"
              fill="#202D41"
            />
            <path
              d="M62.1519 22.345C60.6336 22.345 59.449 21.9446 58.5981 21.1438C57.7472 20.3262 57.3218 19.175 57.3218 17.6901V3.5752H61.2259V17.4649C61.2259 18.0322 61.3677 18.4743 61.6514 18.7913C61.9517 19.0916 62.3688 19.2418 62.9027 19.2418C63.1029 19.2418 63.3031 19.2167 63.5033 19.1667C63.7202 19.1166 63.8871 19.0582 64.0038 18.9915L64.179 22.0197C63.5283 22.2366 62.8526 22.345 62.1519 22.345Z"
              fill="#202D41"
            />
            <path
              d="M45.8561 8.48035C47.1074 8.48035 48.242 8.77232 49.2597 9.35627C50.2941 9.92354 51.1033 10.7327 51.6873 11.7838C52.2712 12.8183 52.5632 14.0279 52.5632 15.4127C52.5632 16.7975 52.2712 18.0154 51.6873 19.0665C51.1033 20.101 50.2941 20.9102 49.2597 21.4941C48.242 22.0614 47.1074 22.345 45.8561 22.345C44.1376 22.345 42.7862 21.8028 41.8018 20.7183V26.9999H37.8977V8.68056H41.6266V10.2322C42.5943 9.0643 44.0042 8.48035 45.8561 8.48035ZM45.1804 19.1416C46.1815 19.1416 46.999 18.8079 47.633 18.1406C48.2837 17.4565 48.609 16.5472 48.609 15.4127C48.609 14.2781 48.2837 13.3772 47.633 12.7098C46.999 12.0258 46.1815 11.6837 45.1804 11.6837C44.1793 11.6837 43.3535 12.0258 42.7028 12.7098C42.0688 13.3772 41.7518 14.2781 41.7518 15.4127C41.7518 16.5472 42.0688 17.4565 42.7028 18.1406C43.3535 18.8079 44.1793 19.1416 45.1804 19.1416Z"
              fill="#202D41"
            />
            <path
              d="M14.1715 6.59491V20.6628C14.1715 22.0286 13.0635 22.6139 11.7027 21.9895L2.46884 17.658C1.10806 17.0141 0 15.3946 0 14.0288V3.10234L5.36535 0L11.7027 2.96576C13.0635 3.60964 14.1715 5.2291 14.1715 6.59491Z"
              fill="#1A9365"
            />
            <path
              opacity="0.302"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 3.06332L5.36535 5.58031V0L0 3.06332Z"
              fill="white"
            />
            <path
              d="M7.56192 7.84367L11.5665 9.71678C11.8386 9.83385 12.0525 9.71678 12.0525 9.46313C12.0525 9.18997 11.8386 8.87778 11.5665 8.76071L7.56192 6.8876C7.28976 6.77053 7.07593 6.8876 7.07593 7.14125C7.07593 7.3949 7.3092 7.7266 7.56192 7.84367Z"
              fill="#D9F0D6"
            />
            <path
              d="M7.56192 4.72184L11.5665 6.59495C11.8386 6.71202 12.0525 6.59495 12.0525 6.3413C12.0525 6.06814 11.8386 5.75595 11.5665 5.63888L7.56192 3.76577C7.28976 3.6487 7.07593 3.76577 7.07593 4.01942C7.07593 4.27307 7.3092 4.58526 7.56192 4.72184Z"
              fill="#D9F0D6"
            />
            <path
              d="M2.60489 8.6437L11.5666 12.8387C11.8387 12.9558 12.0526 12.8387 12.0526 12.585C12.0526 12.3119 11.8387 11.9997 11.5666 11.8826L2.60489 7.66812C2.33273 7.55105 2.1189 7.66812 2.1189 7.92177C2.1189 8.19493 2.33273 8.52663 2.60489 8.6437Z"
              fill="#D9F0D6"
            />
            <path
              d="M2.60489 11.7655L11.5666 15.9605C11.8387 16.0776 12.0526 15.9605 12.0526 15.7069C12.0526 15.4337 11.8387 15.1215 11.5666 15.0044L2.60489 10.7899C2.33273 10.6729 2.1189 10.7899 2.1189 11.0436C2.1189 11.3168 2.33273 11.6485 2.60489 11.7655Z"
              fill="#D9F0D6"
            />
            <path
              d="M2.60489 14.8873L11.5666 19.0823C11.8387 19.1994 12.0526 19.0823 12.0526 18.8287C12.0526 18.5555 11.8387 18.2433 11.5666 18.1263L2.60489 13.9118C2.33273 13.7947 2.1189 13.9118 2.1189 14.1654C2.1189 14.4386 2.33273 14.7703 2.60489 14.8873Z"
              fill="#D9F0D6"
            />
          </svg>
        </a>

        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="Constant.url_fee_dashboard_page"
              >
                Quản lý phí
                <span></span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="Constant.url_agency_list_page"
              >
                <span>Cộng tác viên</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="Constant.url_organization_list_page"
              >
                <span>Tổ chức</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="Constant.url_financial_normal_plan_dashboard"
              >
                <span>Sức khỏe tài chính</span>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLink]="Constant.url_questionaire"
              >
                <span>Kế hoạch tài chính</span>
              </a>
            </li> -->
          </ul>
        </div>

        <ul id="nav-authen">
          <li>
            <a
              class="nav-link btn-border"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <strong>{{ userProfile?.shortName }}</strong>
              <svg
                class="nav-authen__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <circle
                  cx="16"
                  cy="16"
                  r="16"
                  fill="url(#paint0_linear_461_1050)"
                />
                <path
                  d="M9.14282 22.2965C9.14282 22.2965 9.14282 21.3616 9.14282 21.2374C9.14282 19.8667 11.1977 18.6201 13.4424 18.1819C13.4424 18.1819 14.129 17.8094 13.9415 17.1228C13.255 16.2512 13.0675 15.3163 13.0675 15.3163C12.9433 15.253 12.7559 15.1288 12.6317 15.0047C12.4442 14.7539 12.1959 14.0064 12.2592 13.4465C12.3201 12.9474 12.4442 13.1348 12.5075 13.0107C12.0084 11.8883 12.2592 10.4543 12.8192 9.3952C14.0024 7.33791 16.4955 7.96118 16.8704 8.70862C19.2394 8.27282 19.1761 12.0125 18.8036 12.9474C18.8036 12.9474 18.9886 13.0107 18.9886 13.8823C18.9277 14.8172 18.1803 15.3772 18.1803 15.3772C18.1803 15.6279 17.8687 16.3754 17.3696 17.062C16.9337 17.9336 17.8054 18.121 17.8054 18.121C20.0501 18.5568 22.105 19.8034 22.105 21.1741C22.105 21.2983 22.105 22.2332 22.105 22.2332C22.105 23.1072 18.7403 24.0421 15.6239 24.0421C12.5708 24.0421 9.14282 23.7305 9.14282 22.2965Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_461_1050"
                    x1="32"
                    y1="16"
                    x2="-1.57518e-06"
                    y2="16"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#89D5A3" />
                    <stop offset="1" stop-color="#5CB8C8" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
              >
                <path
                  d="M1.23552 0L0 1.23552L5 6.23552L10 1.23552L8.76448 0L5 3.76448L1.23552 0Z"
                  fill="#313131"
                />
              </svg>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <!-- <a routerLinkActive="active" [routerLink]="Constant.url_private_info_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.75 3.63636C3.75 1.62806 5.42893 0 7.5 0C9.57107 0 11.25 1.62806 11.25 3.63636C11.25 5.64467 9.57107 7.27273 7.5 7.27273C5.42893 7.27273 3.75 5.64467 3.75 3.63636ZM7.5 1.45455C6.25736 1.45455 5.25 2.43138 5.25 3.63636C5.25 4.84135 6.25736 5.81818 7.5 5.81818C8.74264 5.81818 9.75 4.84135 9.75 3.63636C9.75 2.43138 8.74264 1.45455 7.5 1.45455Z"
                                        fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 15.2727C0 11.2561 3.35786 8 7.5 8C11.6421 8 15 11.2561 15 15.2727V16H0V15.2727ZM1.54642 14.5455H13.4536C13.0845 11.6754 10.5597 9.45455 7.5 9.45455C4.44029 9.45455 1.91549 11.6754 1.54642 14.5455Z"
                                        fill="#313131" />
                                </svg>
                                <span>Thông tin tài khoản</span>
                            </a>
                            <a routerLinkActive="active" [routerLink]="Constant.url_change_password_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M8.25 10.0455V13.1364H6.75V10.0455H8.25Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12 6.18182V4.63636C12 2.07577 9.98528 0 7.5 0C5.01472 0 3 2.07577 3 4.63636V6.18182H0V17H15V6.18182H12ZM7.5 1.54545C5.84315 1.54545 4.5 2.9293 4.5 4.63636V6.18182H10.5V4.63636C10.5 2.9293 9.15685 1.54545 7.5 1.54545ZM1.5 7.72727V15.4545H13.5V7.72727H1.5Z"
                                        fill="#313131" />
                                </svg>
                                <span>Đổi mật khẩu</span>
                            </a>
                            <a routerLinkActive="active" [routerLink]="Constant.url_investor_list_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M6.75 6.18182V13.1364H5.25V6.18182H6.75Z" fill="#313131" />
                                    <path d="M9.75 6.18182V13.1364H8.25V6.18182H9.75Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.95943 0H11.0406L11.7906 2.31818H15V3.86364H13.5V17H1.5V3.86364H0V2.31818H3.20943L3.95943 0ZM4.79057 2.31818H10.2094L9.95943 1.54545H5.04057L4.79057 2.31818ZM3 3.86364V15.4545H12V3.86364H3Z"
                                        fill="#313131" />
                                </svg>
                                <span>Yêu cầu xóa tài khoản</span>
                            </a> -->
              <a (click)="logout()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M0 0H9.16028V1.7H1.66551V15.3H9.16028V17H0V0Z"
                    fill="#313131"
                  />
                  <path
                    d="M12.4913 3.89792L17 8.5L12.4913 13.1021L11.3136 11.9L13.8119 9.35H4.16377V7.65H13.8119L11.3136 5.1L12.4913 3.89792Z"
                    fill="#313131"
                  />
                </svg>
                <span>Đăng xuất</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="page-container">
    <div class="main-content">
      <router-outlet></router-outlet>
      <router-outlet name="popup"></router-outlet>
    </div>
  </div>
</div>
