<div id="app" class="app">
    <div>
      <router-outlet></router-outlet>
    </div>
</div>

<div id="overlay" style="display: none;"></div>

<img src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none;">

<img id="fileUpload" src="./assets/images/error/disconnect.png" width="20" height="20" style="display: none;">
