// Imports
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Constant } from "../../constant";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare let $: any;
// Component Decorator
@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.html',
  styleUrls: ['./maintenance.scss']
})

// Component Class
export class MaintenanceComponent implements OnInit, OnDestroy {
  public Constant = Constant;
  resource: any = {};
  myInterval: any;
  constructor(
      public router: Router,
      public sanitizer:DomSanitizer,
  ) {

  }
  ngOnInit() {
    this.myInterval  = setInterval(() => {
      // to do
    }, 10 * 60 * 1000)
  }
  ngOnDestroy(): void {
      clearInterval(this.myInterval);
  }
}