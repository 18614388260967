import { Observable }           from 'rxjs'
import { Injectable }           from '@angular/core';
import { CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot, 
    Router,
    CanActivate}  from '@angular/router';
import { UserService } from './user.service';

export interface CanComponentDeactivate {
    confirmChangeRouter: (url?) => Observable<boolean> | Promise<boolean> | boolean;
  }
  
  @Injectable({ providedIn: 'root' })
  export class UserRouteAccessDeactivate  implements CanActivate {
    constructor(
      private userService: UserService,
      private router: Router
    ) {

    }
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let result = this.userService.checkAuthorUrl(route);
      if (result) {
        return true;
      } else {
        this.router.navigate([this.userService.firstUrl()]);
        return false;
      }
    }
  }