<div class="notFound">
  <div class="container">
    <div class="row">
        <div class="col-12">
            <div class="notFound-img" style="text-align: center">
                <img src="/assets/images/home/404.png" alt="">
              </div>
        </div>
        <div class="col-12" style="text-align: center;">
          <p style="font-size: 2rem; color:#034581;">Không tìm thấy trang bạn yêu cầu</p>
            <a class="btn btn--home" routerLink="{{Constant.url_home}}">
                Về trang chủ
              </a>
        </div>
    </div>
  </div>
</div>