import { State, Action, StateContext, Actions, ofActionSuccessful } from '@ngxs/store';
import { StoreState, StoreInfoUser, StoreToken, GetInfoUser, OpenOTP, SetDataOTP, SetTimeOTP, StoreTheme, StoreSourceInfos, GetAllResource, GetProducts, GetOrganizationList } from './app.actions';
import { Injectable } from '@angular/core';
import { Dispatcher } from './base/dispatcher';
import { UserService } from '../common/service/user.service';
import { UserProfile } from '../common/models/users.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NewOTPComponent } from '../common/components/otp/otp.component';
import { LocalStorageService } from 'ngx-webstorage';
import { EventManagerService } from '../common/service/event-manager.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppService } from './app.service';
declare let $: any;
export interface AppStateModel {
    states: any;
    userProfile: any;
    token: string;
    theme: any;
    sourceInfos: any;
    organizationList: any;
    products: any;
    otp: any;
}

export function initAppState(): AppStateModel {
    return {
        userProfile: new UserProfile(),
        states: []
    } as AppStateModel;
}

@State<AppStateModel>({
    name: 'App',
    defaults: initAppState()
})
@Injectable()
export class AppState {
    callApi$ = new Subject();
    constructor(
        private actions$: Actions,
        private dispatcher: Dispatcher,
        private appService: AppService,
        private modalService: NgbModal,
        private localStorage$: LocalStorageService,
        public eventManger: EventManagerService,
    ) {
        this.callApi$.pipe(
            debounceTime(500),
            ).subscribe((event)=> { 
              this.getProfileUser(event);
            });
    }

    @Action(StoreState)
    StoreTag(ctx: StateContext<AppStateModel>, action: StoreState) {
        const states = action.payload;
        ctx.patchState({
            states
        });
    }
    @Action(StoreToken)
    StoreToken(ctx: StateContext<AppStateModel>, action: StoreToken) {
        const token = action.payload;
        ctx.patchState({
            token
        });
    }
    @Action(StoreTheme)
    StoreTheme(ctx: StateContext<AppStateModel>, action: StoreTheme) {
        const theme = action.payload;
        ctx.patchState({
            theme
        });
    }
    @Action(GetOrganizationList)
    GetOrganizationList(ctx: StateContext<AppStateModel>, action: GetOrganizationList) {
        const body = {
            page: 1,
            size: 1000,
        }
        return this.appService.getOrganizationList(body)
        .subscribe((res: any) => {
            if (!res.body.data) {
                return;
            }
            const organizationList = (res.body.data.list || []);
            ctx.patchState({
                organizationList
            });
        })
    }
    @Action(StoreInfoUser)
    StoreInfoUser(ctx: StateContext<AppStateModel>, action: StoreInfoUser) {
        // const userProfile = action.payload;
        // this.authorService.setAuthorities(userProfile);
        // ctx.patchState({
        //     userProfile
        // });
    }
    @Action(StoreSourceInfos)
    StoreSourceInfos(ctx: StateContext<AppStateModel>, action: StoreSourceInfos) {
        const sourceInfos = action.payload;
        ctx.patchState({
            sourceInfos
        });
    }
    @Action(GetInfoUser)
    GetInfoUser(ctx: StateContext<AppStateModel>, action: GetInfoUser) {
        const isFirst = action.payload;
        this.callApi$.next(isFirst);
    }
    getProfileUser(isFirst) {
        // return this.userService.getProfileAPI(true, isFirst);
    }
    @Action(GetAllResource)
    GetAllResource(ctx: StateContext<AppStateModel>, action: GetInfoUser) {
        return this.appService.getAllResource()
        .subscribe((res: any) => {
            ctx.patchState({
                sourceInfos: res.body.data
            });
        })
    }
    @Action(GetProducts)
    GetProducts(ctx: StateContext<AppStateModel>, action: GetProducts) {
        let body: any = {
            page: 1,
            pageSize: 1000,
            types: ['TRADING_FUND', 'NEW_FUND']
        }
        return this.appService.getProducts(body)
        .subscribe((res: any) => {
            ctx.patchState({
                products: (res.data.rows || []).map((product) => {
                    product.ownerId = (product.owner || {}).id;
                    return product;
                })
            });
        })
    }
    @Action(OpenOTP)
    OpenOTP(ctx: StateContext<AppStateModel>, action: OpenOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const payload =  Object.assign({},action.payload || {});
        const user = this.localStorage$.retrieve('USER_PROFILE') || {};
        const options: NgbModalOptions = {
            backdrop : 'static',
            keyboard : false
        };
        options.centered = true;
        options.windowClass  = 'otp-order-label';
        const modalRef = this.modalService.open(NewOTPComponent, options);
        const properties = payload.isReopen ? otp : payload;
        modalRef.componentInstance.phone = payload.phone || user.phone;
        Object.keys(properties || {}).forEach((key) => {
            modalRef.componentInstance[key] = properties[key] || {};
        });
        if (!payload.isReopen) {
            Object.keys(properties || {}).forEach((key) => {
                otp[key] = properties[key] || {};
            });
            otp.startTime = Date.now();
            otp.failTimes = 0;
            modalRef.componentInstance.startTime = otp.startTime;
        }
        ctx.patchState({
            otp
        });
    }
    @Action(SetTimeOTP)
    SetTimeOTP(ctx: StateContext<AppStateModel>, action: SetTimeOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const time = action.payload;
        otp.startTime = time;
        ctx.patchState({
            otp
        });
    }
    @Action(SetDataOTP)
    SetDataOTP(ctx: StateContext<AppStateModel>, action: SetDataOTP) {
        const otp = Object.assign({}, ctx.getState().otp || {});
        const payload = action.payload;
        Object.keys(payload).forEach((key) => {
            otp[key] = payload[key];
        });
        this.eventManger.broadcast({
            name: 'set-data-otp',
            content: {
                data: Object.assign({}, otp.data || {}),
                func: otp.func
            }
          })
        ctx.patchState({
            otp
        });
    }
}
