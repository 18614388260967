import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { AppQueries } from 'src/app/state';
import { Constant } from '../../constant';
import { AccountService } from '../../service/account.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'account-layout',
    templateUrl: './account-layout.component.html',
    styleUrls: [
        'account-layout.component.scss'
    ]
})
export class AccountLayoutComponent implements OnInit {
    theme: any = {};
    Constant = Constant;
    subscriptions?: Subscription[] = [];
    userProfile: any;
    constructor(
        private appQueries: AppQueries,
        private accountService: AccountService
    ) {
        
    }
    getYear() {
        let date = new Date();
        return date.getFullYear();
    }
    ngOnInit() {
        this.subscriptions = [
            this.appQueries.userProfile$
            .subscribe((userProfile) => {
                this.userProfile = userProfile;
            }),
            this.appQueries.theme$
            .subscribe((theme) => {
              this.theme = theme;
            })
        ];
    }
    logout() {
        this.accountService.logout();
    }
}
